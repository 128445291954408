import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

import Modal from './ModalPromo';
import ModalReferral from './modalReferral';

import "../Assets/SCSS/header.scss";
import Logo from "../Assets/images/bath_logo.svg";
import google from "../Assets/images/google.png";
import bild from "../Assets/images/bild.png";
import bestHouzz from "../Assets/images/bestHouz2021.png";
import renomark from "../Assets/images/renomark_logo.png";
import bestofhomestars from "../Assets/images/best-of-homestars.png";
import logo1 from "../Assets/images/google5star.png";
import logo2 from "../Assets/images/bild-logo-orig.webp";
import logo3 from "../Assets/images/bestHouz2021.png";
import logo4 from "../Assets/images/renomark_logo.png";
import logo5 from "../Assets/images/best-of-homestars.png";
import logo6 from "../Assets/Icons/newspaper-folded.png";
import promotinal from "../Assets/images/promotionagmDeal.png";
import OtherServices from "./OtherServices/OtherServices";

const ReferralModal = () => {
  const timeBannerShown = 7000;
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!window.location.pathname.match("referrals") && !sessionStorage.bannerSkipped) {
      setTimeout( () => {

        if (!window.location.pathname.match("referrals"))
          setDialogOpen(true);

      }, timeBannerShown );
    }
  }, []);

  return (
    <ModalReferral  open={isDialogOpen} handleClose={() => { sessionStorage.setItem('bannerSkipped', true); setDialogOpen(false); }} />
  );

};

class Header extends Component {
  
  constructor(props) {
    super();
    this.state = {
      opened: false,
    };
    this.handler = {};
  }

  mobileMenuClick = () => {
    this.setState({
      opened: !this.state.opened,
    });
  };

  forceClose = () => {
    this.setState({
      opened: false,
    });
  };

  handlePromoPopUp = () => {
    this.handler.updateState();
  }

  render() {
    const email = localStorage.getItem("email");
    const pass = localStorage.getItem("pass");

    let region = "";
    if (sessionStorage.getItem("region")) {
      region = sessionStorage.getItem("region");
    } else {
      region = "";
    }

    let linkButton =
      email && pass ? (
        <a
          href="/get-started"
          onClick={this.forceClose}
          className="start-btn empty-btn"
        >
          Get Started
        </a>
      ) : (
        <a
          href="/get-started"
          onClick={this.forceClose}
          className="start-btn empty-btn"
        >
          Get Started
        </a>
      );

    const renderButton = () => {
      const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
      // if(region==='vancouver' || region ==='calgary'){
      if (isKitchenApp) {
        return (
          <Link to="/get-started" className="blue-btn margin-auto">
            Get Started
          </Link>
        );
      } else {
        return linkButton;
      }
    };

    return (
      <section className={`header ${this.state.opened ? "opened" : null}`}>
        <div className="container">
          <div className="header-cnt">
            <Link to="/" className="logo" onClick={this.forceClose}>
              <img src={Logo} />
            </Link>

            {/* <div className="propmotinalMobile" to="/promotion-agm">
              <div 
              onClick={this.handlePromoPopUp}
              >
                <img src={promotinal} />
              </div>
            </div> */}

            <button className="menu-btn" onClick={this.mobileMenuClick}>
              <span>Menu</span>
            </button>
            <div className="navigation">
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.forceClose}
                    to="/"
                    className="nav-btn home-btn"
                  >
                    Home
                  </NavLink>
                </li>
               <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.forceClose}
                    to="/wet-basement"
                    className="nav-btn home-btn"
                  >
                    Wet Basement & Underpinning
                  </NavLink>
                </li>
                {/*  <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.forceClose}
                    to="/our-work"
                    className="nav-btn ow-btn"
                  >
                    Our Work
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.forceClose}
                    to="/about-us"
                    className="nav-btn about-btn"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.forceClose}
                    to="/why-agm"
                    className="nav-btn why-btn"
                  >
                    Why AGM
                  </NavLink>
                </li> */}

                <li>
                  <div className="nav_services">
                    <OtherServices />
                  </div>
                </li>
              </ul>
              <div className="header-actions">

                {/* <div to="/promotion-agm" className="promotinal">
                  <div 
                  onClick={this.handlePromoPopUp}
                  >
                    <img src={promotinal} />
                  </div>
                </div> */}

                <div className="headerLogos">
                  {this.props.isShowlogos && (
                    <>
                      <div className="logoContainer">
                        <a
                          href="https://homestars.com/companies/2807659-agm-basements"
                          target="_blank"
                        >
                          <img src={bestofhomestars} />
                        </a>
                      </div>
                      <div className="logoContainer bild">
                        <img src={bild} />
                      </div>
                      <div className="logoContainer best">
                        <a
                          href="https://www.houzz.com/professionals/design-build-firms/agm-renovations-pfvwus-pf~126163196"
                          target="_blank"
                        >
                          <img src={bestHouzz} />
                        </a>
                      </div>
                      <div className="logoContainer">
                        <img src={renomark} />
                      </div>
                    </>
                  )}

                  <div className="logoContainer google">
                    <a
                      href="https://www.google.com/search?q=AGM+Renovations"
                      target="_blank"
                    >
                      <img src={google} />
                    </a>
                  </div>
                </div>

                {/* <a href="/" className="login-btn empty-btn">Login</a> */}
                {renderButton()}
                {/* <ReferralModal/> */}
              </div>
            </div>
          </div>
        </div>

        <Modal handler={this.handler} />

      </section>
    );
  }
}

export default Header;
