import React from 'react';

import {
  TopSection,
  WorksGallery,
  VideoTestimonials,
  Intro,
  CallToAction,
} from '../../Containers/OurWork';


import prjvprv1 from "../../Assets/images/tstm1.jpg";
import prjvprv2 from "../../Assets/images/tstm2.jpg";
import prjvprv3 from "../../Assets/images/tstm3.jpg";
import prjvprv4 from "../../Assets/images/tstm4.jpg";
import prjvprv5 from "../../Assets/images/tstm5.jpg";

import videoPreview from "../../Assets/videos/Main Bathroom Testemonial preview.mp4";

import KleinHero from "../../Assets/images/virtual_tours/mtpotbaths.jpg";

const recentProjectData = [
  {
    "Heading": "45 Buggey Ln (Bathroom)",
    "HeroBG": KleinHero,
    "virtualTour": "https://my.matterport.com/show/?m=hFu83Xhpp1i",
  },
];

export const OurWorkPage = (props) => {

  let phone, email, locations;

  const importAll = (r) => {
    return r.keys().map(r);
  }

  const galleryImages = importAll(
    require.context(
      "../../Assets/images/BathroomGallery/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  const topSectionProps = {
    text: 'From spacious master ensuites to small compact condo bathrooms, our recently finished projects speak for themselves.',   
  }

  if (props.data) {
    if (props.isKitchenApp) {
      phone = props.data.kitchen_phone;  
    } else {
      phone = props.data.bathroom_phone;
    }
    
    email = props.data.email;
    locations = props.data.location;
  }
  
  const CalltoAction = {
    title: "Your Dream Bathroom is Only a Phone Call Away",
    text: <>
        We’ve helped countless Greater Toronto homeowners improve their bathrooms over the years – are you next? Call <a href='tel:{phone}'>{phone}</a> today to discover how AGM can help turn your old, outdated bathroom into the modern design of your dreams.",
       </>,
    phone: phone
  }

  const introData = {
    desc: <>
      Try our <u className="blue">3D Creator</u> and get your new bathroom design and quote today.
      </>
  }

      //* Get started using our 3D Creator or call <a href='tel:{phone}'>{phone}</a> to get a free quote

  return (
    <>
      <TopSection recentProjectData={recentProjectData} {...topSectionProps} />
      <VideoTestimonials items={[
          { videoId: '1ch6DPAXZYk', previewVideo: videoPreview },
          { videoId: '7F4ZcDON7AU', previewImage: prjvprv1 },
          { videoId: '5ZTVhlbCo5w', previewImage: prjvprv2 },
          { videoId: 'z9nRWBHOmwE', previewImage: prjvprv3 },
          { videoId: 'O8boL1uOdCM', previewImage: prjvprv4 },
          { videoId: 'ciBjGrZhi-8', previewImage: prjvprv5 },
      ]} />
      <WorksGallery images={galleryImages} />
      <Intro {...introData} />
      {/* <CallToAction {...CalltoAction} /> */}
    </>
  )
}