import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WindowsOurListing } from "../Home/WindowsOurListing";
import "./whyAgm.scss";

import Modal from "./Modal";

// import LatestWorkOne from "../../Assets/images/ourWorkGalery/listingImages/slide1.png";
// import LatestWorkTwo from "../../Assets/images/ourWorkGalery/listingImages/slide2.png";
// import LatestWorkThree from "../../Assets/images/ourWorkGalery/listingImages/slide3.png";
// import LatestWorkFour from "../../Assets/images/ourWorkGalery/listingImages/slide4.png";
// import LatestWork5 from "../../Assets/images/ourWorkGalery/listingImages/slide5.jpg";
    

const handler = {};

export const WhyAgm = () => {

  // const latestWorksItems = [
  //   LatestWorkOne,
  //   LatestWorkTwo,
  //   LatestWorkThree,
  //   LatestWorkFour,
  //   LatestWork5
  // ];

  return (
    <>
    <section className="about-section-2" style={{ paddingBottom: "0px" }} >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0px",
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        {/* <WhoWeAre /> */}
        <section style={{ paddingBottom: "20px" }} id="process">
          <div className="container">
            <h2 style={{ marginBottom: "0", paddingBottom: "0" }}>
              AGM EXCLUSIVE OFFER
            </h2>
            <div className="content">
              <h3
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  fontSize: "2em",
                  paddingBottom: "0",
                }}
              >
                Price Match Guarantee!
              </h3>
            </div>
          </div>
        </section>

        <div id="agm-difference-top" style={{ padding: "0px", marginTop: "0" }}>
          <p style={{ textAlign: "center" }}>
            We believe that each homeowner deserves nothing but the best
            workmanship, top quality materials, project completed on time and
            renovation backed by a solid warranty. At AGM Renovations, we
            believe in transparency and honesty, therefore we are proud to offer
            to all our clients:
          </p>
        </div>

        <ul>
          <li>
            <b>Quoted Price Guarantee</b> – No hidden fees, no hidden prices:
            what you see is what you pay. As long as you do not change the scope
            of work you selected, your quoted price is guaranteed from the day you receive your quote to the day your project is completed! – We will
            absorb all additional costs (not you)!
          </li>
          <br />
          <li>
            <b>Timeline Guarantee</b> – To show our commitment to completing
            your project on time, we will commit to pay $100.00 for every business day we
            are late. As long as you don't make changes to your project scope/selections during the renovation, we commit to $100/business day. 
          </li>
          <br />
          <li>
            <b>5-Year UNLIMITED Warranty</b> – The best way to prove to our
            clients that our work and materials are the best in the industry, we
            guarantee and warrant our clients’ projects with a 5-year unlimited
            warranty, (it covers 100% of everything we do).
          </li>
          <br/>
          {/* <li>
            <Link style={{ color: '#0025ff', fontSize: '0.9em',
              color: 'rgb(6, 174, 222)',
              fontSize: '1.2em',
              fontWeight: '600',
              lineHeight: '32px',
              borderBottom: '2px solid rgb(6, 174, 222)'
          }} to="/promotion-agm" >
              Holiday Cash Giveaway - A CHANCE TO WIN $20,000
            </Link>
          </li> */}
          {/* <br/> */}
        </ul>

        <div
          id="agm-difference-top"
          style={{ padding: "0px", marginTop: "20px", marginBottom: "40px" }}
        >
          <p>
            Should any company offer a lower quote and is not afraid to put
            these statements in writing - AGM will price-match their quote.
          </p>
        </div>

        <Link to="/get-started" className="blue-btn desktop-btn">
          Free Consultation
        </Link>


      <section style={{ paddingBottom: "20px", marginTop: '40px' }} >
          <div className="container maximize">
            <h2 style={{ marginBottom: "0",  paddingBottom: "0", textTransform: 'upperCase' }}>
              AGM Maximizes your home Value
            </h2>
          </div>
        </section>

        <div id="agm-difference-top" style={{ padding: "0px", marginTop: "0" }}>
          <p style={{ textAlign: "center" }}>
            As the most reputable renovation company in the country AGM Renovations is known and trusted by many home buyers and realtors throughout the country. Home Renovations done by AGM increases home resale value significantly and these homes are often sold over asking price. See example listings below.
          </p>
        </div>
      </div>
    </section>
        
    <WindowsOurListing
        title=""
        subtitle=""
        noTitle={true}
        hideButton={true}
        handler={handler}
    >
      <Modal handler={handler} />
    </WindowsOurListing>

    </>
  );
};
