import React, { useState } from 'react';
import Slider from "react-slick";
import Modal from '@material-ui/core/Modal';
import { sliderSettings } from '../Home/constants';
// import { recentProjectData } from './recentProjectData';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const TopSection = (props) => {

  const { 
    title = 'Our Work',
    text,
    recentProjectData = []
  } = props;

  const [isOpen, setOpen] = useState(false)
  const [videoUlr, setVideoUrl] = useState("")
  const [virtualTourUlr, setVirtualTourUrl] = useState("")

  const [currentSlide, setSlideIndex] = useState(0);

  return (
    <section style={{ marginBottom: '80px' }} className="video-intro our-works">

      <div style={{
            paddingBottom: '200px',
            paddingTop: '30px'
      }} className="container">
        <div className="cnt">
          <h2 style={{ marginTop: '30px' }}>{title}</h2>
          <h4>{text}</h4>
        </div>

        <div style={{
              position: 'absolute',
              marginTop: '-40px',
              left: '0px',
        }} className='works-slider mtp'>
          <Slider  {...{ ...sliderSettings, speed: 1000, autoplaySpeed: 3000, autoplay: false,
                beforeChange: function(currentSlide, nextSlide) {
                  setSlideIndex(nextSlide);
                },
          }}>
            {
              recentProjectData.map((item, index) => {
                return (
                  <div key={index} className='latest-work-slider-item video'>
                    <div
                      style={{
                        maxHeight: "314px",
                        width: "100%",
                        background: `url(${item.HeroBG})`,
                        backgroundPosition: " center",
                        position: "relative",
                        boxShadow: '0px 2px 10px rgba(0,0,0,0.7)'
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          height: "100%",
                          position: "relative"
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <button
                            style={{
                              borderRadius: "100%",
                              backgroundColor: "#00AFDA",
                              width: "100px", 
                              height: "100px",
                              display: "grid",
                              placeItems: "center",
                              marginTop: '44px',
                              cursor: "pointer",
                              border: "0px",
                            }}
                            // href="https://tours.reelsparrow.com/v/hNLdnXt"
                            onClick={() => { setOpen(true);  setVirtualTourUrl(item.virtualTour) }}
                          >
                            <svg style={{ marginLeft: "9px" }} width="40" height="48" viewBox="0 0 45 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M41.4062 22.2461L7.03125 1.93359C4.19922 0.273438 0 1.93359 0 5.9375V46.5625C0 50.2734 3.90625 52.5195 7.03125 50.6641L41.4062 30.3516C44.4336 28.4961 44.4336 24.1016 41.4062 22.2461ZM39.8438 27.6172L5.46875 47.9297C4.39453 48.5156 3.125 47.832 3.125 46.5625V5.9375C3.125 4.375 4.6875 4.17969 5.46875 4.66797L39.8438 24.9805C40.8203 25.5664 40.8203 27.0312 39.8438 27.6172Z" fill="white" />
                            </svg>
                          </button>

                        </div>
                        <div>
                          <h3 style={{ color: '#fff', fontSize: '1.3em' }} className="recent_content">{item.Heading}</h3>
                          <span style={{ color: '#fff' }}>See More of this project</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>

        <Modal
            open={isOpen}
            onClose={() => { setOpen(false); }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{
              display: '     flex',
              flexWrap:      'wrap',
              flexDirection: 'column-reverse',
              alignContent:  'center',
              marginTop:     '80px',
              width: '80%',
              marginLeft: '10%',
              background: '#fff',
              position: 'relative'
            }}>
              <iframe src={virtualTourUlr} height={680} width={'100%'}/>
              <HighlightOffIcon style={{
                position: 'absolute',
                background: '#fff',
                borderRadius: '50%',
                right: '-10px',
                top: '-10px',
                cursor: 'pointer',
                transform: 'scale(1.5)'
              }} onClick={() => { setOpen(false); }}/>
            </div>
            
        </Modal>

      </div>
    </section>
  )
}