import React, {Component} from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";

import { Helmet } from "react-helmet";
import axios from "axios";
import {API_URL} from "../../Constants/default";
import "./GetStarted.scss";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

function removeParam(paramName) {
  let searchParams = urlSearchParams;
  searchParams.delete(paramName);

  if (window.history.replaceState) {
    let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
    window.history.replaceState(null, '', newUrl);
  }
}

class GetStarted extends Component {
  static propTypes = {};

  state = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    email: "",
    phone: "",
    theSource: "",
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  processGetStartedForm = (e) => {
    // const url = 'https://bathroom-api.herokuapp.com/customers';
    e.preventDefault();
    const url = "https://hooks.zapier.com/hooks/catch/2915270/bbkhce2/";
    let todayDate = new Date().toISOString().slice(0, 10);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        full_name: this.state.firstName + " " + this.state.lastName,
        email: this.state.email,
        city: this.state.city,
        phone: this.state.phone,
        date: todayDate,
      }),
    }).then((res) => {
      if (res.ok === true) {
        window.location = "/thank-you";
      }

      return;
    });
  };

  // processGetStartedForm = async (e) => {
  //   e.preventDefault();
  //   const res = await axios.post(`${API_URL}/customers`, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     first_name: this.state.firstName,
  //     last_name: this.state.lastName,
  //     city: this.state.city,
  //     email: this.state.email,
  //     phone: this.state.phone,
  //   });
  //   if (res.data.success) {
  //     sessionStorage.setItem("customer_id", res.data.customer_id);
  //     this.props.history.push("/finishes");
  //   }
  // };



  componentDidMount() {
    let vars = {};

    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
        if (localStorage) {
          localStorage.setItem("vars", JSON.stringify(vars));
        }
      }
    );

    if ("agmsource" in vars) {
      sessionStorage.setItem("agmsource", vars.agmsource);
    }

    const theSource = localStorage.getItem('vars');
    const parsedSource = theSource && JSON.parse(theSource).agmsource;

    const possibleSources = [
      '1310 news',
      '580 AM',
      'AM900 (Hamilton)',
      'CHCH TV',
      'CHML',
      'CKTB',
      'Company vehicle',
      'CP24',
      'CTV',
      'Home Show',
      'AdWords',
      'Internet Search',
      'Magazine/flier',
      'News Talk 610 (Niagara)',
      '680 News',
      'Referral from previous client (CSR team will collect contact info once connected to the customer)',
      'Social Media',
      'Facebook',
      'Instagram',
      'YouTube',
      'Pinterest',
      '3C Contact Services.',
      'Nexa Call Center',
      'Word of mouth'
    ];

    if (possibleSources.includes(decodeURI(parsedSource))) {
      const decodedURI = decodeURI(parsedSource);
      this.setState({
        theSource: decodedURI,
      });
    }

    if (this.mount?.parentNode) {
      this.mount.parentNode.parentNode.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  }



  render() {

    const isKitchenApp     = process.env.REACT_APP_NAME === 'kitchens';

    let Renovation_Type__c = 'Foundation Cracks Repair';
    
    return (
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>

      <Box mt={8} mb={15} ref={(mount) => { this.mount = mount; }} >
        <Container maxWidth="sm">
          <Box className="text-center">
            <Box>
              <h2>Get Started</h2>
            </Box>
            <Box mt={2} mb={5}>
              <h4 style={{ fontWeight: 400 }}>Enter your info below to get started!</h4>
            </Box>
          </Box>
          <form
            //ref={ref => this.formRef = ref}
            method="POST"
            id="estimateform"
            //onSubmit={this.processGetStartedForm}
            action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          >
            <input type="hidden" name="oid" value="00D5Y000002U1by"/>
            <input
              type="hidden"
              name="retURL"
              value={'https://agmcrackrepair.com/thank-you'}
            />

            {this.state.theSource && (
              <input
                id="lead_source"
                name="lead_source"
                type="hidden"
                value={this.state.theSource || ""}
              />
            )}

            <input
              type="hidden"
              name="Lead_Origin__c"
              value="Web"
            />

            <input
              type="hidden"
              id="Renovation_Type__c"
              name="Renovation_Type__c"
              value={Renovation_Type__c}
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={6}>
                <Box>
                  <TextField id="standard-basic" label="Address" name="address" fullWidth onChange={this.handleInputChange} />
                </Box>
              </Grid> */}
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="city"
                    label="City"
                    name="city"
                    fullWidth
                    onChange={this.handleInputChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="phone"
                    label="Phone"
                    name="phone"
                    fullWidth
                    onChange={this.handleInputChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <p className="agreement-text">
                    By clicking "continue", you agree to our Terms/Conditions
                    &amp; <a href="/privacy">Privacy Policy</a>.
                  </p>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Fab
                    variant="extended"
                    color="primary"
                    className="full-width"
                    type="submit"
                  >
                    Continue
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
      </>
    );
  }
}

export default GetStarted;
