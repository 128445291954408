import React from 'react';

import {
  TopSection,
  WorkSteps,
  CreatorFeatures,
  Enjoy,
} from '../../Containers/HowItWorks';

import bannerLaptop from "../../Assets/images/creator_image_1.png";
import HiwStepOne from "../../Assets/images/hiw_step_1.png";
import HiwStepTwo from "../../Assets/images/bathroom_design.jpg";
import HiwStepThree from "../../Assets/images/hiw_step_3.png";
import HiwStepFour from "../../Assets/images/hiw_step_4.png";
import HiwStepFive from "../../Assets/images/hiw_step_5.png";
import HiwStepSix from "../../Assets/images/hiw_step_6.jpg";
import HiwStepSeven from "../../Assets/images/hiw_step_7.png";
import HiwStepEight from "../../Assets/images/hiw_step_8.png";
import CreatorImageOne from "../../Assets/images/creator_image_1.png";
import CreatorImageTwo from "../../Assets/images/creator_image_2.png";
import CreatorImageThree from "../../Assets/images/creator_image_3.png";
import CreatorImageFour from "../../Assets/images/creator_image_4.png";
import CreatorImageFive from "../../Assets/images/creator_image_5.png";
import CreatorImageSix from "../../Assets/images/creator_image_6.png";
import CreatorImageSeven from "../../Assets/images/creator_image_7.png";
import enjoyImg from "../../Assets/images/enjoy_bg.jpg";

export const HowItWorksPage = () => {

  const topSectionProps = {
    bannerImgProps: {
      alt: 'laptop with bathroom on the screen',
      src: bannerLaptop,
    },
    country: sessionStorage.getItem('country_auto'),
  };

  const workStepsItems = [
    {
      title: 'Tell Us About Your Existing Bathroom',
      description: 'To get started, answer a few quick questions about your current bathroom.',
      imgProps: {
        src: HiwStepOne,
      }
    },
    {
      title: 'Browse Fixtures & Features',
      description: 'Scroll through luxury fixtures, flooring and finishes and choose your favorites.',
      imgProps: {
        src: HiwStepTwo,
      }
    },
    {
      title: 'Create Your Dream Bathroom',
      description: 'View your selections, then mix and match to create your perfect bathroom.',
      imgProps: {
        src: HiwStepThree,
      }
    },
    {
      title: 'Get a Guaranteed Quote',
      description: 'Finalize your bathroom design then request a hassle-free, no-obligation quote instantly.',
      imgProps: {
        src: HiwStepFour,
      }
    },
    {
      title: 'Schedule a Start Date',
      description: 'Choose a convenient date for our AGM team to start your bathroom renovation.',
      imgProps: {
        src: HiwStepFive,
      }
    },
    {
      title: 'Feel and Touch Your Materials',
      description: 'We bring all materials and finishes to your home so you can view your selections and make last-minute changes.',
      imgProps: {
        src: HiwStepSix,
      }
    },
    {
      title: 'Construction Begins',
      description: 'Our expert team will build out your custom designed bathroom, on time and on budget.',
      imgProps: {
        src: HiwStepSeven,
      }
    },
    {
      title: 'Enjoy Your New Bathroom',
      description: 'Within two weeks you’ll have your dream bathroom, designed 100% to your specifications.',
      imgProps: {
        src: HiwStepEight,
      }
    },
  ]

  const creatorFeaturesItems = [
    {
      text: (
        <>
          <strong>3D Design</strong> Upload your bathroom dimensions
                                     and see your space in 3D. From here, it’s easy to choose
                                     fixtures, flooring and finishes, so you can design your dream
                                     bathroom instantly.
        </>
      ),
      imgProps: {
        src: CreatorImageOne,
      }
    },
    {
      text: (
        <>
          <strong>Luxury Tiling</strong>, Scroll through our collection
                                        of ready-to-order tiles and flooring. Can’t decide? Click to
                                        swap in different tiles and see your design updates instantly
                                        within the 3D Creator.</>
      ),
      imgProps: {
        src: CreatorImageTwo,
      },
    },
    {
      text: (
        <>
          <strong>Spa-Inspired Showers</strong>, Choose from a variety
                                               of bathroom shower designs, including concealed showers,
                                               exposed rain showers, waterfall showers and more, creating a
                                               spa-like experience at home.
        </>
      ),
      imgProps: {
        src: CreatorImageThree,
      },
    },
    {
      text: (
        <>
          <strong>Standard &amp; Soaking Tubs</strong>, Redefine
                                                      relaxation by integrating a contemporary new freestanding or
                                                      built-in tub. Browse our selection of tubs designed for all
                                                      bathroom sizes and configurations.
        </>
      ),
      imgProps: {
        src: CreatorImageFour,
      },
    },
    {
      text: (
        <>
          <strong>Fixtures &amp; Faucets</strong>, Finalize your custom
                                                 bathroom design with the perfect faucets and other essential
                                                 fixtures. Tap to swap out features or mix and match different
                                                 styles to create your ideal bathroom
        </>
      ),
      imgProps: {
        src: CreatorImageFive,
      },
    },
    {
      text: (
        <>
          <strong>Toilet &amp; Add-Ons</strong>, Dual-flush,
                                               water-efficient models, waterless toilets and even bidets—view
                                               our wide selection of luxury toilets, complete with a variety
                                               of features and add-ons to refine your bathroom design.
        </>
      ),
      imgProps: {
        src: CreatorImageSix,
      },
    },
    {
      text: (
        <>
          <strong>Vanity &amp; Storage</strong>, Anchor your bathroom
                                               design with a stylish, customizable vanity. A bathroom
                                               centerpiece, our vanities can be updated with fixtures and
                                               other features to maximize space and storage.
        </>
      ),
      imgProps: {
        src: CreatorImageSeven,
      },
    },
  ]

  const enjoyProps = {
    title: <><span>Indulge In Your</span> New Bathroom</>,
    imgProps: {
      src: enjoyImg,
      alt: 'bathroom',
    }
  }

  return (
    <>
      <TopSection {...topSectionProps} />
      <WorkSteps items={workStepsItems}/>
      <CreatorFeatures items={creatorFeaturesItems}/>
      <Enjoy {...enjoyProps} />
    </>
  )
}