import React from 'react';
import Reviews from '../../Containers/Reviews';

import TestimonialsReviewerTwo from '../../Assets/testimonials_images/pic-24.png';
import TestimonialsReviewerThree from '../../Assets/testimonials_images/3.png';
import TestimonialsReviewerFour from '../../Assets/testimonials_images/4.png';
import TestimonialsReviewerFive from '../../Assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from '../../Assets/testimonials_images/pic-04.png';
import TestimonialsReviewerSeven from '../../Assets/testimonials_images/pic-16.png';



const testimonialsItems = [
    {
      text: `We had our basement done including kitchenette,washroom, bedroom, living room and space for a gym and ping pong table.  It was finished on time and all of the contractors were extremely professional and did fantastic work.  It transformed a cold unusable space into a fantastic area to entertain and relax!  Special thanks to our project manager Aday for making sure that the job was completed to our complete satisfaction.`,
      name: 'Mark Koyama',
      photo: TestimonialsReviewerTwo,
    },
    {
      text: `The kitchen renovation in our home by AGM has been recently completed and we are happy to say that our experience has been wonderful. The trades did a great job and most importantly kept the kitchen area tidy throughout. Garbage and debris were regularly removed and we never had the stress about being exposed to any kind of renovation mess. The work is great and all the details were looked into meticulously! AGM is a company of choice for renovation!`,
      name: 'George Winfield',
      photo: TestimonialsReviewerSeven,
    },
    {
      text: `We are very pleased with the bathroom renovation done by AGM. Right from the consultation stage we were made very comfortable and the design aspect made it absolutely clear what was expected finally. The final bathroom is exactly what it was supposed to be. The workers were very polite throughout the project and kept the area very tidy. AGM has highly skilled tradesmen who execute the project in a professional manner. We are very happy with our new bathroom and overall project experience!`,
      name: 'Lisa Lopez',
      photo: TestimonialsReviewerThree,
    },
    {
      text: `Three Piece Bathroom project has been an amazing journey with this Company. My wife and I are stoked and well pleased with the quality of material, labour and service. Above and beyond at every turn! They have completed the work in record time, on time, on budget and proven to be creators in their own right. Qualifying themselves as competent workers in times of shifting change. These are teams that work extraordinary to meet expectations and more!!`,
      name: 'Anthony Mistretta',
      photo: TestimonialsReviewerSix,
    },
    {
      text: `AGM renovations— is the best renovation company l recommend . There are so many great reasons . From financing to materials selection, and most importantly PROJECT MANAGEMENT is the best .project manager AYAD — very professional ,he proved to my family his best services,the way he has done the project . He listens to the clients needs and he does all his best to meet clients demands. Every part of the project workers —- Plumbing,electrical, flooring ,doors , paintings,cleaning We thanks to everyone. Most importantly AGM finished the project earlier than expected time . My family and family friends recommend AGM RENOVATIONS`,
      name: 'mohammad mia',
      photo: TestimonialsReviewerFive,
    },
    {
      text: `We hired AGM for our bathroom renovation. The work was completed with minimal disruption of our daily lives at home. We now have a modern bathroom to take pride in. Vadim the renovator is very reliable, professional and pays attention to minute details. We have already recommended AGM to our friends and family.Whoever has seen our new bathroom are very positive about AGM! We are sure AGM will get few projects from our friends!`,
      name: 'Inez Lapointe',
      photo: TestimonialsReviewerFour,
    }
];

export const ReviewsPage = (props) => {


  return (
    <>
        <Reviews
            loopItems={testimonialsItems}
            paragraph={''}
            title={'AGM Renovation Reviews | AGM Foundation Crack Repair | AGM Renovations'}
            description={'Click here to see what AGM Renovations reviews have to say about crack repair services from the team at AGM Renovations.'}
        >
            <p style={{ textAlign: "center" }}>
            AGM Renovations prides itself on its meticulous approach to the crack repair process, with every step
            evaluated to ensure absolute customer satisfaction. Their team of experts is adept at efficiently and effectively
            resolving problems ranging from foundation cracks to drywall imperfections, a fact that is easily shown in
            various AGM Renovations reviews.
            <br/><br/>
            As part of its commitment to offering high-quality crack repair and bathroom renovation projects at
            competitive prices, AGM Renovations continually assesses different strategies to earn the trust of its
            customers. Such an approach has seen the implementation of a price match guarantee, among other things.
            <br/><br/>
            Moreover, AGM Renovations provides a quoted price guarantee on all crack repair projects, ensuring that
            customers only pay what they see. AGM Renovations reviews also show the company commits to paying
            $100.00 for each business day beyond the proposed finish date.
            <br/><br/>
            If you require assistance with crack repair, do not hesitate to contact AGM Renovations today for a free quote.
            Take a moment to read through our AGM Renovations reviews for further information.
          </p>
        </Reviews>
    </>
  )

}