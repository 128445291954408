import React, { Component } from "react";
import { Link } from "react-router-dom";

import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
import { SliderText } from "./SliderText";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import Laptop from "../../Assets/images/laptop_agm-design-tool.png";
import youtubeLogo from "../../Assets/images/youtube-icon.png";
import LaptopMobile from "../../Assets/images/laptop_agm-design-tool_mobile.png";
import blueCheckmark from "../../Assets/images/checkmark-blue.png";
import whiteLarge from "../../Assets/images/banner-white-background-blob.png";
// import ModalVideo from 'react-modal-video'
import VideoModal from "../../Common/videoModal";
import ReactPlayer from "react-player";
import { render } from "@testing-library/react";

import Videobg from '../../Assets/videos/stock-footage-cement-wall-cracks-caused-by-poor-construction.webm';
import CracksVideo from '../../Assets/videos/Cracks.mp4';

// export const TopSection = (props) => {
// const tool3dRegions = ['ottawa', 'gta', 'calgary', 'vancouver'];

// const getStations = async (stationsCountry, that) => {

//     const stationsToLoad = {
//       CA:  () => import('../../Common/Stations'),
//       US: () => import('../../Common/StationsUS')
//     }

//     if (!stationsToLoad[stationsCountry]) stationsCountry = 'CA';
//     that.state.countryCode = stationsCountry;
//     console.error('that.state.countryCode', that.state.countryCode);
//     const mod = await stationsToLoad[stationsCountry]();
//     console.error('thmod.defaulte', mod.default);
//     that.Stantions = mod.default;
// }
// laptopVideo: 's0hz5E1E2ZU',
// const videoId = countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA';


export class TopSection extends Component {
  
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      countryCode: props.location?.countryCode,
    }
    this.videos = {
      laptopVideo: this.state.countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
      HGTV: '5kVBooRtR4Q',
    };
    this.openModal = this.openModal.bind(this)
    this.handleHGTVStar = this.handleHGTVStar.bind(this)
    this.Stantions = null
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;

    if (countryCode && this.state.countryCode !== countryCode) {
      this.setState({ countryCode });
      this.videos = {
        laptopVideo: countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
        HGTV: '5kVBooRtR4Q',
      };
    }
  }

  openModal () {
    this.setState({ isOpen: true, videoId: this.videos.laptopVideo })
  }

  handleHGTVStar () {
    this.setState({ isOpen: true, videoId: this.videos.HGTV })
  }
  

  render(){

    const { title, subtitle, listTitle, listItems, backgroundImage, className, children, noPhoto } = this.props;
    const isKitchenApp = process.env.REACT_APP_NAME === 'kitchens';

    // if(region==='calgary' || region==='vancouver' ){
    if (!isKitchenApp && false) {
      return (
        <>
          <section className="home-section">
            <section className="home-banner">
              <div className="welcome-text">
                <h1>Bathroom Renovations</h1>
                <div className="bottom-items"><span></span>Done Right!</div>             
              </div>

              { this.state.isOpen && 
                  <VideoModal
                    isOpen={this.state.isOpen}
                    videoId={this.state.videoId}
                    handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                  />
              }

              <div className="d-flex checkmark-box">
                <div className="laptop-box">

                  <div className="desktop-visible">
                    <img onClick={this.openModal} className="youtube-icon" src={youtubeLogo}/>
                    <img onClick={this.openModal} className="laptop-img" src={Laptop}></img>
                  </div>

                  <div className="mobile-visible laptop">
                    <img onClick={this.openModal} className="youtube-icon" src={youtubeLogo}/>
                    <img onClick={this.openModal} className="laptop-img-mobile" src={LaptopMobile}></img>

                  </div>
                </div>

                <div className="right-box">
                  <img src={whiteLarge} className="white-blob"/>
                  <h4>NO NEED TO</h4>
                  <ul className="checkmark-list">
                    <li>
                      <img src={blueCheckmark} className="float-left"/><b>Deal with contractors</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left"/><b>Invite strangers to your home</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left"/><b>Drive to multiple showrooms</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left"/><b>And no more wasted time!</b>
                    </li>
                  </ul>
                  {/* <Link to="/design" className="blue-btn desktop-btn">
                    Get Free Quote
                  </Link> */}
                   <Link to="/get-started" className="blue-btn desktop-btn">
                    Free Consultation
                    </Link>
                </div>
              </div>

            </section>
          </section>
          <SliderText showButton={true} />
          {this.state.countryCode === 'CA' 
            ? (<Stations/>)
            : (< StationsUS/>)
          }
          {/* {this.Stantions && <this.Stantions />} */}
        </>
      )
    } else {

      // TODO move welcome-text up on < 1100px width
      return (
        <>
          <section 
           className={`home-section modified ${className ? className : ''}`} 
           style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 100%',  marginTop: '-220px' }}
          //  backgroundPositionY: '0px',
          >
            <div className="banner-cover" />


            <video id="background-video" style={{  width: '100%', position: 'absolute', top: '90px' }} autoPlay={true} loop muted 
                src={CracksVideo} type="video/mp4">
            </video>


            <section className="home-banner">
              <div className="welcome-text" >
                <h1>Wet Basement & Underpinning</h1>
                <p className="welcome-sub">{subtitle}</p>
                <h4>{listTitle}</h4>
                <ul>
                  {listItems && listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <Link to="/get-started" className="blue-btn desktop-btn">Free Consultation</Link>
              </div>
              { this.state.isOpen && 
                  <VideoModal
                    isOpen={this.state.isOpen}
                    videoId={this.state.videoId}
                    handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                  />
              }



            </section>
            {/* {children} */}
          </section>

          {/* {this.Stantions && <this.Stantions />} */}
          <SliderText showButton={false} />
          {this.state.countryCode === 'CA' 
            ? (<Stations/>)
            : (< StationsUS/>)
          }
        </>
      )
    }
  }
}
export default TopSection;