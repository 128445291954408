import React from 'react';

import {
  TopSection,
  Intro,
  HowItWorks,
  Testimonials,
  OurAwards,
  LatestWorks,
  CallToAction,
} from '../../Containers/Home';
import { Helmet } from "react-helmet";


import sectionBackground from '../../Assets/images/cracrep.jpg';
import po from '../../Assets/images/po.png';
import baneerOffer from '../../Assets/images/bathsspecoffer.gif';

import hiwImg1 from '../../Assets/images/icon-01.png';
import hiwImg2 from '../../Assets/images/icon-02.png';
import hiwImg3 from '../../Assets/images/icon-03.png';

import LatestWorkOne from "../../Assets/images/BathroomGallery/6.jpg";
import LatestWorkTwo from "../../Assets/images/BathroomGallery/dfgr.jpg";
import LatestWorkThree from "../../Assets/images/BathroomGallery/BathReno25.d2eda50f.jpg";
import LatestWorkFour from "../../Assets/images/BathroomGallery/fer.jpg";
import LatestWorkFive from "../../Assets/images/BathroomGallery/se.jpg";
import callToActionBackground from '../../Assets/images/lets_get_started.png';

import TestimonialsReviewerTwo from './../../Assets/testimonials_images/pic-10.png';
import TestimonialsReviewerThree from './../../Assets/testimonials_images/pic-08.png';
// import TestimonialsReviewerFour from './../../Assets/testimonials_images/4.png';
import TestimonialsReviewerFive from './../../Assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from './../../Assets/testimonials_images/pic-04.png';
import TestimonialsReviewerSeven from './../../Assets/testimonials_images/pic-16.png';
// import TestimonialsReviewerEight from './../../Assets/testimonials_images/8.png';
// import TestimonialsReviewerNine from './../../Assets/testimonials_images/9.png';

const handler = { isShowPromo: false };

export const HomePage = (props) => {
  // const topSectionProps = {
  //   title: 'Bathrooms Made Simple',
  //   backgroundImage: sectionBackground,
  // }

  const handleScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const promoRef = document.getElementById('promo2Elem');
    if (promoRef) {
      if (winScroll >= 70) {
        if (!handler.isShowPromo) {
          promoRef.classList.add('visible');
          handler.isShowPromo = true;
        }
      } else {
        if (handler.isShowPromo) {
          promoRef.classList.remove('visible');
          handler.isShowPromo = false;
        }
      }
    }
  };
  document.addEventListener("scroll", handleScroll);


  const topSectionProps = {
    className: 'home-section--kitchen',
    title: 'Bathroom Renovations',
    subtitle: 'Done Right!',
    // listTitle: 'Hassle Free Quote without:',
    // listItems: ['Dealing with contractors', 'Inviting strangers to your home', 'Visiting multiple showrooms', 'Wasting time!'],
    backgroundImage: sectionBackground,
  }

  const howItWorksItems = [
    {
      title: 'INSPECTION',
      text: (
        <>
        <p>The  AGM Foundation crack expert conducts a thorough inspection of your basement foundation walls and provides a quote to permanently repair all foundation cracks.</p>
        </>
      ),
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg1,
      },
    },
    {
      title: 'THE REPAIR',
      text: (
        <>
        <p>Once quote is approved, AGM technician completes all repairs using AGM’s exclusive permanent high-pressure polyurethane repair method.</p>
        </>
      ),
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg2,
      },
    },
    {
      title: 'WORK COMPLETE',
      text: (
        <>
          <p>
          Upon completing all repairs AGM will provides a written, industry exclusive, Unlimited, 15 year, transferrable warranty for all foundation repairs performed.
          </p>
        </>
      ),
imgProps: {
  src: hiwImg3,
      },
    },
  ];

const latestWorksItems = [
  LatestWorkOne,
  LatestWorkTwo,
  LatestWorkThree,
  LatestWorkFour,
  LatestWorkFive,
];

const callToActionProps = {
  text: 'Your free consultation and quote on your bathroom design and renovation project is only a quick phone call away.',
  subtitle: "Choosing AGM Renovations means guaranteed...",
  guaranteeItems: [
    'Convenience From the Comfort of Your Home',
    'Expert Installation Team with 20+ Years of Experience',
    'Guaranteed Three-Week Installation',
    'Quoted price Guaranteed - NO Overages',
    '5-Year Unlimited Warranty'
  ],
  backgroundImage: callToActionBackground,
  desc: <>Try our <u className="blue">3D Creator</u> and get your new bathroom designed and built just for you from
    the comfort of your home.</>
};

const testimonialsItems = [
  {
    text: `AGM did our bathroom for a massage business just in the past
      summer. Great sales and design experience. Your company's
      generosity culture showed at all stages of the project. Had
      a few hiccups with selected products not being available
      from the suppliers, but with patience we got through it all.
      You guys have very courteous and professional employees. Had
      great experiences with some of them. Can't recall their
      specific names.`,
    name: 'K.T. Addo',
    photo: TestimonialsReviewerTwo,
  },
  {
    text: `We hired AGM to renovate our bathroom which we are very
      happy with. From initial meeting with Andrea to working with
      Kate at the design studio on structure and design to making
      the vision come to life with Johnny the project manager.
      Johnny kept us updated throughout the process and was very
      helpful and informative.`,
    name: 'Carm Ragno',
    photo: TestimonialsReviewerFive,
  },
  {
    text: `AGM has finished our bathroom really well. We have a
      beautiful living area. Love the signature wall unit that AGM
      has installed. Workers are very professional. We believe AGM
      has given us a great value for the price we paid. We thank
      everyone at AGM. For the great work!`,
    name: 'Josie Vierra',
    photo: TestimonialsReviewerThree,
  },
  {
    text: `It was a great experience to watch our bathroom work done by
      AGM workers. Very experienced and well organized team. We
      have a nice entertainment area, a nice kitchen and now a
      very newly built beautiful bathroom. Overall we are very
      happy with our bathroom. The team at AGM has our
      recommendations to all families looking to finish their
      bathroom!`,
    name: 'Kay Walter',
    photo: TestimonialsReviewerSix,
  },
  {
    text: `We had our bathroom finished by AGM, and I'm completely
      happy with the result. Of the 3 estimates we had done, they
      were the cheapest AND gave us the most finished space. They
      finished the project on time, and their staff were
      knowledgeable and friendly.`,
    name: 'Jan Genoch',
    photo: TestimonialsReviewerSeven,
  }
]

let region = '';
if (sessionStorage.getItem("region")) {
  region = sessionStorage.getItem("region");
} else {
  region = ''
}

const newIntro = {
  countryCode: props?.data?.countryCode,
  title: (region === 'calgary' || region === 'vancouver') ? "Backed by decades of experience." : "THE EASIEST WAY TO QUOTE, DESIGN",
  subtitle: (region === 'calgary' || region === 'vancouver') ? "Expert bathroom renovations since 1998." : "AND RENOVATE YOUR BATHROOM!",
  text: (region === 'calgary' || region === 'vancouver') ? "In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As the oldest company in the industry, we are proudly part of this elite group, with a proven track record of finishing bathroom renovations on time and on budget." : "AGM helps you design your dream bathroom…..you select your materials without leaving your home. No need to waste time driving around the city selecting finishes - We bring all materials to your door for you to touch and feel."
}

return (
  <>
    <Helmet>
      <title>{process.env.REACT_APP_TITLE}</title>
      <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
      <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
      <meta name="robots" content="index"/>
    </Helmet>

    <TopSection {...topSectionProps} location={props.location}>
      <img src={po} alt="" className="home-page_po-image" />
    </TopSection>
    <Intro {...newIntro} />
    <HowItWorks items={howItWorksItems} />
    <Testimonials items={testimonialsItems} />
    <OurAwards />
    <LatestWorks items={latestWorksItems} />
    <CallToAction {...callToActionProps} />
  </>
)
}